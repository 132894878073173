








import { computed, defineComponent, watch } from '@vue/composition-api';
import consoleMenu from '@/composition/consoleMenu';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'Home',
  components: {
    FcRoleLoading,
  },
  setup(_, context) {
    // ロールの取得
    const isLoadingMyRole = computed(() => myAttributes.isLoading);
    const myRoleSettings = computed(() => myAttributes.myRoleSettings);

    if (!isLoadingMyRole.value && consoleMenu.displayMenu.length)
      context.root.$router.replace(consoleMenu.displayMenu[0].to);

    watch(
      () => [consoleMenu.displayMenu, isLoadingMyRole.value],
      () => {
        if (!isLoadingMyRole.value && consoleMenu.displayMenu.length)
          context.root.$router.replace(consoleMenu.displayMenu[0].to);
      }
    );

    return {
      isLoadingMyRole,
      myRoleSettings,
    };
  },
});
